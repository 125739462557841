import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Components/Home/Home';
import {ToastContainer} from "react-toastify"

function App() {
  return (
    <div>
         <ToastContainer/>
      <Home/>

    </div>
  );
}

export default App;
