import "./Home.css"

import { useState,useRef,useEffect } from "react";
import step1 from "../../Resources/step1.png"
import step2 from "../../Resources/step2.png"
import step3 from "../../Resources/step3.png"
import map from "../../Resources/map.png"
import axios from "axios"
import {toast} from "react-toastify"


function Home() {

// to resemble me-qr.com
    
  
    const [submitbtndisabled,setsubmitbtndisabled] = useState(false)
    var emailref = useRef()

function navigatetosomewhere (e) {
    e.preventDefault()
   
  
    
}

function navigatetofreeqr ()
{
    toast.success("Coming Soon! Enter email above to receive notification of launch date!",{autoClose:10000,theme:"colored"})
}


const validateEmail = (email) => {
    let emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let confirm =  emailregex.test(email)
    return confirm
  
  };



function emailbtnsubmit () {
    setsubmitbtndisabled(true)
    var emailvalidation = validateEmail(emailref.current.value)
    toast.success(emailvalidation,{autoClose:10000,theme:"colored"})

    if (emailvalidation == true)
    {
        axios.post("https://mastertechnologysolutions.live/signup",{
            email:emailref.current.value
        })
        .then((response)=> {

            if (response.data.status == "bad")
            {
                toast.error(response.data.message,{autoClose:10000,theme:"colored"})
            }
            else if (response.data.status == "good")
            {
                toast.success("Thank you for your interest! Qrcodeconstructor.com will launch soon and you will be notified",{autoClose:10000,theme:"colored"})
            
            }
            setsubmitbtndisabled(false)

          
    
        })
        .catch(err =>{
            setsubmitbtndisabled(false)
            console.log(err)
        })
    
    }
    

    else if (emailvalidation == false)
    {
        setsubmitbtndisabled(false)
        toast.error("Please enter a valid email!",{autoClose:10000,theme:"colored"})
    }

}

    

    return ( <div className="container">
    
    <div className="row text-center">
        <div className="col"><span id="coming_text">Coming Soon in 2023</span>
        </div>
    </div>
    <div className="row text-center">
        <div className="col"><span id="coming_text_next">Enter Email To Recieve Launch Date and Special Promotions</span>
        </div>
    </div>
    <div className="row text-center">
        <div className="col"><input ref={emailref} placeholder="example@gmail.com" type="text" /><button disabled={submitbtndisabled} onClick={emailbtnsubmit} id="submit_email_btn" className="btn btn-success" >Submit</button></div>
    </div>
    
<div className="row">
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><span id="big_text_top_home">Create & Customize Your Dynamic QR Code</span>
    <span id="smaller_text_bottom">Easily generate, manage, and statistically track your QR codes</span>

    </div>
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><div id="qr_home_container">
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-globe"></i> Url/Link</div>
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-wifi"></i> WiFi</div>
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-address-book"></i> Vcard</div>
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-image"></i> Image</div>
        
    </div>
        
    </div>
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><div id="qr_home_container">
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-paragraph"></i>Text</div>
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-file-pdf"></i> PDF</div>
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-file-audio"></i> MP3</div>
        <div onClick={navigatetofreeqr} className="individual_qr_container"><i className="fa-solid fa-comment-sms"></i> SMS</div>
    </div>
    
    
    </div>
</div>
<div className="row text-center">
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"></div>
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8"><div id="show_nextsteps">
        <span onClick={navigatetofreeqr} id="show_more_link">Show More</span>
    <button onClick={navigatetofreeqr} id="next_step_btn" className="btn btn-primary">Next step to QR Code </button>
    </div>

    </div>
</div>
<div className="row mt-5">
    <div className="col"><span id="create_text">Create QR Code in 3 Steps</span></div>
</div>
<div className="row mt-3 text-center">
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><div className="steps_container"><img className="steps" src={step1} />
    <div className="step_text">Step 1</div>
    <div className="step_text_directions">Choose the type</div>
    </div>  

     </div>

   
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><div className="steps_container"><img className="steps" src={step2} />
    <div className="step_text">Step 2</div>
    <div className="step_text_directions">Generate QR code</div>
    </div>

    </div>
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><div className="steps_container"><img className="steps" src={step3} />
    <div className="step_text">Step 3</div>
    <div className="step_text_directions">Customize & download</div>
    </div>

    </div>
    </div>
<div className="row mt-5">
    <div className="col"><span id="powering_text">Our QR Code solution is powering millions of QR Code scans around the globe</span></div>
</div>
<div className="row mt-3">
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><div className="container_howmany"><span className="icon_numbers"><i class="fa-solid fa-industry"></i></span><span className="amount_text">0</span><span className="description_text">QR Codes</span></div>

    </div>
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"><div className="container_howmany"><span className="icon_numbers"><i class="fa-solid fa-camera"></i></span><span className="amount_text">0</span><span className="description_text">Scans</span></div>

    </div>
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 "><div className="container_howmany"><span className="icon_numbers"><i class="fa-solid fa-user"></i></span><span className="amount_text">0</span><span className="description_text">Users</span></div>

    </div>
</div>

<div className="row mt-5">
    <div className="col"><img id="large_map" src={map}/></div>
</div>
<div className="row mt-3">
    <div className="col"><span id="all_features">All features of QR Code Constructor</span></div>
</div>
<div className="row mt-3">
    <div className="col"><div id="all_features_container">
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-qrcode featuresicon"></i> Free QR code</span></div>  
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-file featuresicon"></i>Different QR code types</span></div>  
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-pencil featuresicon"></i>QR codes with design</span></div>  
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-microchip featuresicon"></i>Dynamic QR Codes</span></div>  
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-infinity featuresicon"></i>Unlimited QR code creation</span></div>  
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-magnifying-glass featuresicon"></i>Trackable QR codes</span></div>  
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-infinity featuresicon"></i>Unlimited Scans</span></div>  
    <div className="features_item"><span className="feature_text"><i class="fa-solid fa-chart-simple featuresicon"></i>QR code analytics</span></div>  
    </div>
</div>
</div>

<div className="row mt-3 text-center">
    <div className="col"><button onClick={navigatetofreeqr} id="next_step_btn" className="btn btn-primary">Create qr code</button></div>
</div>

<div className="row mt-5">
    <div className="col"><span id="all_features">Create QR for specified types</span></div>
</div>
<div className="row mt-3">
    <div className="col"><div id="different_types_qr_container">
        <div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-globe emblem"></i>  URL

        </div>
        <div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-wifi emblem"></i>  Wi-Fi

        </div>
        <div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-file-pdf emblem"></i>  PDF

</div>
<div className="qr_types"><i className="fa-solid fa-address-book emblem"></i>  vCard

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-envelope emblem"></i>  Email

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-file-audio emblem"></i>Audio

</div>
<div className="qr_types"><i className="fa-solid fa-paragraph emblem"></i>  Text

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-image emblem"></i>  Image

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-paragraph emblem"></i>  Text

</div>
<div onClick={navigatetofreeqr} className="qr_types"> <i className="fa-brands fa-instagram emblem"></i>  Instagram

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-brands fa-tiktok emblem"></i>  TikTok

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-brands fa-snapchat emblem"></i>  Snapchat

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-brands fa-facebook emblem"></i>  Facebook

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-brands fa-youtube emblem"></i>  Youtube

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-brands fa-x-twitter emblem"></i>  X
</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-location-dot emblem"></i>  Location

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-link emblem"></i>  List of Links

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-utensils emblem"></i>  Menu

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-calendar-days emblem"></i>  Event
</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-brands fa-linkedin emblem"></i>  LinkedIn

</div>
<div onClick={navigatetofreeqr} className="qr_types"><i className="fa-brands fa-reddit emblem"></i>  Reddit

</div>
<div  onClick={navigatetofreeqr} className="qr_types"><i className="fa-solid fa-connectdevelop emblem"></i>  Social Media

</div>

        </div></div>
</div>
<div className="row mt-3 mb-3 text-center">
    <div className="col"><button onClick={navigatetofreeqr} id="try_for_free_btn" className="btn btn-primary">Try for free</button></div>
</div>

    </div> );
}

export default Home;